import React, { useEffect, useState } from 'react';

import {
  Box,
  Heading,
  Grid,
  Text,
  Progress,
  Button,
  useToast
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import api from 'Core/services/api';
import { Liquid } from '@ant-design/plots';

const QueriesInfoBox: React.FC = function () {
  const [total, setTotal] = useState<number>(0);
  const [used, setUsed] = useState<number>(0);

  const toast = useToast();

  const [config, setConfig] = useState<any>();

  useEffect(() => {
    async function getData(): Promise<void> {
      try {
        const { data } = await api.get('/request/api-use');

        setTotal(data.total);
        setUsed(data.used);

        setConfig({
          percent: (data.used / data.total),
          shape: 'diamond',
          outline: {
            border: 4,
            distance: 8
          },
          wave: {
            length: 128
          },
          color: '#0058ff'
        });
      } catch (e) {
        toast({
          title: 'Ops',
          description: 'Algo de errado aconteceu.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    }
    getData();
  }, [toast]);

  return (
    <Box data-testid="query-box">
      <Grid
        templateColumns={{ base: '1fr', lg: '0.8fr 1fr' }}
        alignItems="center"
        border="1px solid"
        borderColor="borderGray"
        bgColor="white"
      >
        <Box
          p="24px"
          h="350px"
        // borderRight="1px solid"
        // borderColor="brand.500"
        >
          {
            config
            && <Box h="300px"><Liquid {...config} /></Box>
          }
        </Box>
        <Box color="gray.800" p="24px">
          <Heading
            fontWeight="500"
            fontSize="30px"
          >
            Consultas de crédito
          </Heading>
          <Box mt="16px">
            <Text fontWeight="600" mb="4px">
              {used}
              /
              {total}
              {' '}
              consultas realizadas
            </Text>
            <Progress value={(used / total) * 100} size="sm" colorScheme="brand" />
            {/* <Link to="/">
              <Text
                textAlign="right"
                mt="8px"
                fontWeight="500"
                color="brand.500"
                transition="0.3s"
                fontSize="13px"
                _hover={{
                  opacity: 0.8
                }}
              >
                Comprar mais créditos
              </Text>
            </Link> */}
          </Box>

          <Box mt="24px">
            <Link to="/configuracoes/pagamento">
              <Button w="100%" colorScheme="brand">Upgrade</Button>
            </Link>
            {/* <QueryModal /> */}
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default QueriesInfoBox;
