import React from 'react';
import { Box } from '@chakra-ui/react';
import Header from 'Components/layout/Header';
import Partners from 'Components/pages/Partners';
import AIVideo from 'Components/pages/AIVideo';
import Analysis from 'Components/pages/Analysis';
import PfPi from 'Components/pages/PfPi';
import Plans from 'Components/pages/Plans';
import Contact from 'Components/pages/Contact';
import Footer from 'Components/layout/Footer';
import Gauge from 'Components/pages/Gauge';

const Home: React.FC = function () {
  return (
    <Box>
      <Header />
      <Partners />
      <Gauge />
      <AIVideo />
      <PfPi />
      <Analysis />
      <Plans />
      <Contact />
      <Footer />
    </Box>
  );
};

export default Home;
