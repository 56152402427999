import React from 'react';

import {
  Stack,
  Grid,
  Box
} from '@chakra-ui/react';
import DashboardTemplate from 'Components/templates/DashboardTemplate';
import Card from 'Components/elements/Card';
import DataGroup from 'Components/elements/DataGroup';
import QueryModal from 'Components/elements/QueryModal';
import Score from 'Components/elements/Score';

const Dashboard: React.FC = function () {
  return (
    <DashboardTemplate>
      <Stack spacing="24px">
        <Grid templateColumns={{ base: '1fr', lg: '0.5fr 1fr' }} gap="24px">
          <Box>
            <Score score={874} name="Antony Leme" />
            <Box mt="24px" w="100%" ml="auto">
              <QueryModal />
            </Box>
          </Box>
          <Box>
            <Card>
              <Stack spacing="16px">
                <DataGroup label="Nome" value="Antony Leme Novais Ferreira" />
                <DataGroup label="CPF" value="000.000.000-00" />
                <DataGroup label="Data de nascimento" value="04/04/2002" />
                <DataGroup label="CEP" value="36771-530" />
                <DataGroup label="Cidade" value="Cataguases" />
                <DataGroup label="Bairro" value="Taquara Preta" />
                <DataGroup label="Rua" value="Governador Francelino Pereira" />
                <DataGroup label="Número" value="15" />
                <DataGroup label="Complemento" value="Não consta" />
              </Stack>
            </Card>

            {/* <Box mt="32px" w="250px" ml="auto">
              <QueryModal />
            </Box> */}
          </Box>
        </Grid>
      </Stack>
    </DashboardTemplate>
  );
};

export default Dashboard;
