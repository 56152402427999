import React, { useEffect, useState } from 'react';
import {
  useToast, Box, Grid, Heading, Text, Input, Button, Flex, FormControl, FormLabel, Link, IconButton
} from '@chakra-ui/react';
import { BsArrowLeft } from 'react-icons/bs';
import { Link as ReactLink, useNavigate, useLocation } from 'react-router-dom';
import useAuth from 'Core/hooks/useAuth';
import api from 'Core/services/api';

function useQuery(): URLSearchParams {
  const { search } = useLocation();

  return new URLSearchParams(search);
}

const Login: React.FC = function () {
  const toast = useToast();

  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const [submiting, setSubmiting] = useState<boolean>(false);
  const navigate = useNavigate();

  const { authenticate, isAuthenticated } = useAuth();

  const query = useQuery();
  const redirect = query.get('redirect');

  useEffect(() => {
    if (isAuthenticated()) navigate('/consultas');
  }, [isAuthenticated, navigate]);

  const submit = async function (): Promise<void> {
    try {
      if (!email) {
        toast({
          title: 'Informe seu email.',
          description: 'Informe seu email para fazer o login.',
          status: 'warning',
          duration: 9000,
          isClosable: true
        });

        return;
      }

      if (!password) {
        toast({
          title: 'Informe sua senha.',
          description: 'Informe sua senha para fazer o login.',
          status: 'warning',
          duration: 9000,
          isClosable: true
        });

        return;
      }

      setSubmiting(true);

      const { data } = await api.post('/auth/login', {
        email,
        password
      });

      authenticate(data.user, data.token);

      if (redirect) {
        navigate(`/${redirect}`);
        return;
      }

      navigate('/consultas');

      setSubmiting(false);
    } catch (e) {
      toast({
        title: 'Ops',
        description: 'Algo de errado aconteceu. Verifique sua senha.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
      setSubmiting(false);
    }
  };

  return (
    <Box>
      <ReactLink to="/">
        <IconButton
          aria-label="return"
          color="white"
          icon={<BsArrowLeft size="24px" />}
          position="absolute"
          top="32px"
          left="32px"
          borderRadius="100px"
          bgColor="transparent"
          _hover={{ bgColor: 'rgba(255,255,255,.1)' }}
        />
      </ReactLink>
      <Grid templateColumns={{ base: '1fr', lg: 'calc(100% - 500px) 500px' }} minH="100vh">
        <Flex color="white" bg="brand.500" alignItems="center" p="80px" d={{ base: 'none', lg: 'flex' }}>
          <Box>
            <Heading as="h1" fontWeight="700" fontSize={{ base: '28px', lg: '50px' }} lineHeight="1.1em" mb="16px">
              Acesso seguro
              <br />
              ao painel de análises.
            </Heading>
            <Text maxW="700px" fontWeight="500">
              Utilize nossa inteligência artificial com base em dados sociais
              e comportamentais para análise e aprovação de crédito em tempo real.
            </Text>
          </Box>
        </Flex>
        <Flex alignItems="center" p="32px">
          <Box>
            <Heading
              as="h2"
              fontFamily="bubble"
              fontWeight="500"
              fontSize="32px"
              mb="50px"
            >
              DATAHOLICS
            </Heading>

            <Box>
              <FormControl mb="24px">
                <FormLabel>Seu email</FormLabel>
                <Input
                  variant="custom"
                  size="lg"
                  type="email"
                  placeholder="Seu email..."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl mb="32px">
                <FormLabel>Sua senha</FormLabel>
                <Input
                  type="password"
                  variant="custom"
                  size="lg"
                  placeholder="*******"
                  mb="8px"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Link href={`/forget-pass?redirect=${redirect}`} color="brand.500">Esqueci minha senha</Link>
              </FormControl>
              <Box textAlign="center">
                <Button
                  colorScheme="brand"
                  w="100%"
                  mb="8px"
                  size="lg"
                  onClick={() => submit()}
                  isLoading={submiting}
                >
                  Acessar plataforma
                </Button>
                <Link href={`/register?redirect=${redirect}`} color="brand.500">Não possuo uma conta</Link>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Grid>
    </Box>
  );
};

export default Login;
