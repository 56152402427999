import React, { useEffect, useState } from 'react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input
} from '@chakra-ui/react';

import InputMask from 'react-input-mask';
import { IoMdBusiness } from 'react-icons/io';
import { HiUser } from 'react-icons/hi';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

import { Link } from 'react-router-dom';

const TypeButton: React.FC<
  { testId?: string, text: string, icon: ReactJSXElement, onClick: () => void }> = function ({
    testId, text, icon, onClick
  }) {
    return (
      <HStack
        px="24px"
        py="16px"
        border="1px solid"
        borderColor="borderGray"
        fontWeight="500"
        cursor="pointer"
        transition="0.3s"
        _hover={{
          bgColor: 'brand.100'
        }}
        onClick={onClick}
        data-testid={testId}
      >
        {icon}
        <Text>{text}</Text>
      </HStack>
    );
  };

TypeButton.defaultProps = {
  testId: undefined
};

const QueryModal: React.FC = function () {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [type, setType] = useState<string | undefined>(undefined);

  useEffect(() => {
    setType(undefined);
  }, [isOpen]);

  return (
    <Box data-testid="query-modal-wrapper">
      <Button
        onClick={onOpen}
        colorScheme="brand"
        w="100%"
        data-testid="query-modal-button"
        size="lg"
      >
        Realizar nova consulta
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent borderRadius="0px" data-testid="query-modal">
          <ModalHeader>Consulta de Crédito</ModalHeader>
          <ModalCloseButton />

          <ModalBody>

            {
              !type
              && (
                <>
                  <Text mb="16px">Escolha um tipo de consulta</Text>

                  <Stack spacing="8px">
                    <TypeButton
                      icon={<HiUser size="25px" />}
                      text="Pessoa física"
                      onClick={() => setType('person')}
                      testId="person-button"
                    />
                    <TypeButton
                      icon={<IoMdBusiness size="25px" />}
                      text="Pessoa jurídica"
                      onClick={() => setType('business')}
                      testId="business-button"
                    />
                  </Stack>
                </>
              )
            }

            {
              !!type
              && (
                <Stack spacing="24px" data-testid="query-modal-form">
                  {
                    ((type === 'person')
                      ? (
                        <>

                          <FormControl data-testid="query-modal-form-name">
                            <FormLabel mb="8px">Nome completo</FormLabel>
                            <Input
                              variant="custom"
                              size="lg"
                              data-testid="name-input"
                              placeholder="Nome completo..."
                            />
                          </FormControl>
                          <FormControl data-testid="query-modal-form-cpf">
                            <FormLabel mb="8px">CPF</FormLabel>
                            <InputMask mask="999.999.999-99">
                              {(inputProps: unknown) => (
                                <Input
                                  {...inputProps}
                                  type="text"
                                  placeholder="000.000.000-00"
                                  variant="custom"
                                  size="lg"
                                  data-testid="cpf-input"
                                />
                              )}
                            </InputMask>
                          </FormControl>
                        </>
                      )
                      : (
                        <FormControl data-testid="query-modal-form-cnpj">
                          <FormLabel mb="8px">CNPJ</FormLabel>
                          <InputMask mask="99.999.999/9999-99">
                            {(inputProps: unknown) => (
                              <Input
                                {...inputProps}
                                type="text"
                                placeholder="00.000.000/0000-00"
                                variant="custom"
                                size="lg"
                                data-testid="cnpj-input"
                              />
                            )}
                          </InputMask>
                        </FormControl>
                      ))
                  }

                  <FormControl id="cep">
                    <FormLabel mb="8px">CEP</FormLabel>
                    <InputMask mask="99999-999">
                      {(inputProps: unknown) => (
                        <Input
                          {...inputProps}
                          type="text"
                          placeholder="00000-000"
                          variant="custom"
                          size="lg"
                          data-testid="cep-input"
                        />
                      )}
                    </InputMask>
                  </FormControl>
                </Stack>
              )
            }

          </ModalBody>

          <ModalFooter>
            {
              type
              && (
                <>
                  <Button
                    variant="ghost"
                    mr="auto"
                    color="gray.400"
                    onClick={() => setType(undefined)}
                  >
                    Alterar
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={onClose}
                  >
                    Cancelar
                  </Button>
                  <Link to="/dashboard/query/1">
                    <Button colorScheme="brand" ml={3} onClick={onClose}>
                      Consultar
                    </Button>
                  </Link>
                </>
              )
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default QueryModal;
