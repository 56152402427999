import React from 'react';
import {
  Container, Button, Grid, Box,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  FormControl,
  Stack,
  Input,
  FormLabel
} from '@chakra-ui/react';
import Score from 'Components/elements/Score';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';

const PFForm: React.FC = function () {
  return (
    <Stack
      spacing="24px"
      data-testid="query-modal-form"
      mt="24px"
      minH="360px"
    >
      <Link to="/register">
        <FormControl data-testid="query-modal-form-name">
          <FormLabel mb="8px">Nome completo</FormLabel>
          <Input
            variant="custom"
            size="md"
            data-testid="name-input"
            placeholder="Nome completo..."
          />
        </FormControl>
        <FormControl data-testid="query-modal-form-cpf">
          <FormLabel mb="8px">CPF</FormLabel>
          <InputMask mask="999.999.999-99">
            {(inputProps: unknown) => (
              <Input
                {...inputProps}
                type="text"
                placeholder="000.000.000-00"
                variant="custom"
                size="md"
                data-testid="cpf-input"
              />
            )}
          </InputMask>
        </FormControl>

        <FormControl id="cep">
          <FormLabel mb="8px">CEP</FormLabel>
          <InputMask mask="99999-999">
            {(inputProps: unknown) => (
              <Input
                {...inputProps}
                type="text"
                placeholder="00000-000"
                variant="custom"
                size="lg"
                data-testid="cep-input"
              />
            )}
          </InputMask>
        </FormControl>
        <Button size="lg" w="100%" colorScheme="brand">
          Consultar
        </Button>
      </Link>
    </Stack>
  );
};

const PJForm: React.FC = function () {
  return (
    <Stack
      spacing="24px"
      data-testid="query-modal-form"
      mt="24px"
      minH="360px"
    >
      <Link to="/register">
        <FormControl data-testid="query-modal-form-cnpj">
          <FormLabel mb="8px">CNPJ</FormLabel>
          <InputMask mask="99.999.999/9999-99">
            {(inputProps: unknown) => (
              <Input
                {...inputProps}
                type="text"
                placeholder="00.000.000/0000-00"
                variant="custom"
                size="lg"
                data-testid="cnpj-input"
              />
            )}
          </InputMask>
        </FormControl>

        <FormControl id="cep">
          <FormLabel mb="8px">CEP</FormLabel>
          <InputMask mask="99999-999">
            {(inputProps: unknown) => (
              <Input
                {...inputProps}
                type="text"
                placeholder="00000-000"
                variant="custom"
                size="lg"
                data-testid="cep-input"
              />
            )}
          </InputMask>
        </FormControl>
        <Button size="lg" w="100%" colorScheme="brand">
          Consultar
        </Button>
      </Link>
    </Stack>
  );
};

const Gauge: React.FC = function () {
  return (
    <Container id="analise" maxW="container.xl" pt={{ base: '20px', lg: '80px' }} pb="0px">
      <Grid
        templateColumns={{ base: '1fr', lg: '500px 500px' }}
        justifyContent="center"
        gap="32px"
      >
        <Box mb={{ base: '32px', lg: 0 }}>
          <Score score={870} name="João Silva" home />
        </Box>
        <Box>
          <Tabs colorScheme="brand">
            <TabList>
              <Tab>Pessoa Física</Tab>
              <Tab>Pessoa Jurídica</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <PFForm />
              </TabPanel>
              <TabPanel>
                <PJForm />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Grid>
    </Container>
  );
};

export default Gauge;
