/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Container, Heading, Grid, Box, useToast
} from '@chakra-ui/react';
import Basic from 'Assets/Basic.svg';
import Intro from 'Assets/Intro.svg';
import Business from 'Assets/Business.svg';
import Plan from 'Components/elements/Plan';
import api from 'Core/services/api';
import IPlan from 'Core/types/IPlans';

const Plans: React.FC = function () {
  const [plans, setPlans] = useState<IPlan[]>([]);

  const toast = useToast();

  useEffect(() => {
    async function getData(): Promise<void> {
      try {
        const { data } = await api.get('/plans/all');
        setPlans(data);
      } catch (e) {
        toast({
          title: 'Ops',
          description: 'Algo de errado aconteceu.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    }
    getData();
  }, []);

  return (
    <Box id="planos">
      <Container maxW="container.xl" py={{ base: '60px', lg: '120px' }}>
        <Box textAlign="center" mb="80px">
          <Heading
            as="h3"
            textTransform="uppercase"
            color="brand.500"
            fontFamily="DM Mono"
            fontSize={{ base: '18px', lg: '24px' }}
            fontWeight="500"
            mb="8px"
          >
            Nossos planos
          </Heading>
          <Heading
            as="h2"
            color="brand.900"
            fontWeight="700"
            fontSize={{ base: '28px', lg: '40px' }}
            maxW="600px"
            mx="auto"
          >
            Encontre o plano certo para sua empresa
          </Heading>
        </Box>

        <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr 1fr' }} gap="24px">
          {
            plans.map((plan) => (
              <Plan
                id={plan._id}
                icon={Business}
                title={plan.name}
                subtitle=""
                key={plan._id}
                price={plan.price}
                subprice="Cobrado mensalmente"
                features={[
                  `${plan.credits} consultas de score PF e PJ`
                ]}
                isPopular={plan.isPopular || false}
              />
            ))
          }
          {/* <Plan
            icon={Basic}
            title="Basic"
            subtitle="Perfeito para começar"
            price={157}
            subprice="Cobrado mensalmente"
            features={[
              '1 Brand',
              '1 User',
              '50 videos and 100 photo assets/mo'
            ]}
          />
          <Plan
            icon={Business}
            title="Business"
            subtitle="Perfeito para começar"
            price={157}
            subprice="Cobrado mensalmente"
            features={[
              '1 Brand',
              '1 User',
              '50 videos and 100 photo assets/mo'
            ]}
            isPopular
          /> */}
        </Grid>
      </Container>
    </Box>
  );
};

export default Plans;
