import React, { useEffect, useState } from 'react';

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Card from 'Components/elements/Card';
import api from 'Core/services/api';
import IHistoryItem from 'Core/types/IHistoryItem';

const HistoryBox: React.FC = function () {
  const navigate = useNavigate();

  const [items, setItems] = useState<IHistoryItem[]>([]);

  const toast = useToast();

  useEffect(() => {
    async function getData(): Promise<void> {
      try {
        const { data } = await api.get('/request/history');

        setItems(data);
      } catch (e) {
        toast({
          title: 'Ops',
          description: 'Algo de errado aconteceu.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    }
    getData();
  }, [toast]);

  return (
    <Card title="Histórico" testId="history-box">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            {/* <Th>Score</Th> */}
            <Th>Nome</Th>
            <Th>CPF/CNPJ</Th>
            <Th>CEP</Th>
            <Th>Data</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            items.map((item) => (
              <Tr
                cursor="pointer"
                _hover={{
                  bgColor: 'gray.50'
                }}
                key={item.id}
              >
                <Td>{item.input.name || item.input.nome}</Td>
                <Td>{item.input.cpf || item.input.cnpj}</Td>
                <Td>{item.input.cep}</Td>
                <Td>{item.date}</Td>
              </Tr>
            ))
          }
        </Tbody>
      </Table>
    </Card>
  );
};

export default HistoryBox;
