import React, { useState } from 'react';

import {
  Box,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import api from 'Core/services/api';
import IScore from 'Core/types/IScore';
import { cpf, cnpj } from 'cpf-cnpj-validator';

interface IQueryBoxForm {
  type: 'person' | 'business',
  setScore: (score: IScore) => void,
  setName: (name: string) => void
}

const QueryBoxForm: React.FC<IQueryBoxForm> = function ({ type, setScore, setName }) {
  const [name, setFormName] = useState<string>();
  const [cpfData, setCpf] = useState<string>('');
  const [cnpjData, setCnpj] = useState<string>('');
  const [cep, setCep] = useState<string>();

  const [submiting, setSubmiting] = useState<boolean>(false);

  const toast = useToast();

  const submit = async function (): Promise<void> {
    if (!name && type === 'person') {
      toast({
        title: 'Informe um nome.',
        description: 'É preciso um nome para realizar a consulta.',
        status: 'warning',
        duration: 9000,
        isClosable: true
      });

      return;
    }

    if (!cpfData && type === 'person') {
      toast({
        title: 'Informe um CPF.',
        description: 'É preciso um CPF para realizar a consulta.',
        status: 'warning',
        duration: 9000,
        isClosable: true
      });

      return;
    }

    if (!cnpjData && type === 'business') {
      toast({
        title: 'Informe um CNPJ.',
        description: 'É preciso um CNPJ para realizar a consulta.',
        status: 'warning',
        duration: 9000,
        isClosable: true
      });

      return;
    }

    if (!cep && type === 'person') {
      toast({
        title: 'Informe um CEP.',
        description: 'É preciso um cep para realizar a consulta.',
        status: 'warning',
        duration: 9000,
        isClosable: true
      });

      return;
    }

    if (!cpf.isValid(cpfData) && type === 'person') {
      toast({
        title: 'CPF Inválido.',
        description: 'Por favor verifique o cpf, e tente novamente.',
        status: 'warning',
        duration: 9000,
        isClosable: true
      });

      return;
    }

    if (!cnpj.isValid(cnpjData) && type === 'business') {
      toast({
        title: 'CNPJ Inválido.',
        description: 'Por favor verifique o CNPJ, e tente novamente.',
        status: 'warning',
        duration: 9000,
        isClosable: true
      });

      return;
    }

    try {
      setSubmiting(true);
      const endpoint = type === 'person' ? 'person' : 'business';

      const { data } = await api.post(`/request/${endpoint}/score`, {
        name,
        cpf: cpfData,
        cnpj: cnpjData,
        cep
      });

      setScore(data);
      setName(data.name);
      setSubmiting(false);
    } catch (e) {
      toast({
        title: 'Ops',
        description: 'Algo de errado aconteceu. Tente novamente.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
      setSubmiting(false);
    }
  };

  return (
    <Box
      color="gray.800"
      p="32px"
      border="1px solid"
      borderColor="brand.500"
      bgColor="white"
    >
      <Heading
        fontWeight="500"
        fontSize="30px"
      >
        Consulta pessoa
        {' '}
        {type === 'person' ? 'física' : 'jurídica'}
      </Heading>

      <Stack spacing="24px" data-testid="query-modal-form" mt="24px">
        {
          ((type === 'person')
            ? (
              <>

                <FormControl data-testid="query-modal-form-name">
                  <FormLabel mb="8px">Nome completo</FormLabel>
                  <Input
                    variant="custom"
                    size="md"
                    data-testid="name-input"
                    placeholder="Nome completo..."
                    value={name}
                    onChange={(e) => setFormName(e.target.value)}
                  />
                </FormControl>
                <FormControl data-testid="query-modal-form-cpf">
                  <FormLabel mb="8px">CPF</FormLabel>
                  <InputMask
                    mask="999.999.999-99"
                    value={cpfData}
                    onChange={(e) => setCpf(e.target.value)}
                  >
                    {(inputProps: unknown) => (
                      <Input
                        {...inputProps}
                        type="text"
                        placeholder="000.000.000-00"
                        variant="custom"
                        size="md"
                        data-testid="cpf-input"
                      />
                    )}
                  </InputMask>
                </FormControl>
                <FormControl id="cep">
                  <FormLabel mb="8px">CEP</FormLabel>
                  <InputMask
                    mask="99999-999"
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                  >
                    {(inputProps: unknown) => (
                      <Input
                        {...inputProps}
                        type="text"
                        placeholder="00000-000"
                        variant="custom"
                        size="lg"
                        data-testid="cep-input"
                      />
                    )}
                  </InputMask>
                </FormControl>
              </>
            )
            : (
              <FormControl data-testid="query-modal-form-cnpj">
                <FormLabel mb="8px">CNPJ</FormLabel>
                <InputMask
                  mask="99.999.999/9999-99"
                  value={cnpjData}
                  onChange={(e) => setCnpj(e.target.value)}
                >
                  {(inputProps: unknown) => (
                    <Input
                      {...inputProps}
                      type="text"
                      placeholder="00.000.000/0000-00"
                      variant="custom"
                      size="lg"
                      data-testid="cnpj-input"
                    />
                  )}
                </InputMask>
              </FormControl>
            ))
        }

        <Button
          size="lg"
          colorScheme="brand"
          isLoading={submiting}
          onClick={() => submit()}
        >
          Consultar
        </Button>
      </Stack>
    </Box>
  );
};

export default QueryBoxForm;
